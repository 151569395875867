import { render, staticRenderFns } from "./resident_vs_non_resident.vue?vue&type=template&id=02542f7d&scoped=true&"
import script from "./resident_vs_non_resident.vue?vue&type=script&lang=js&"
export * from "./resident_vs_non_resident.vue?vue&type=script&lang=js&"
import style0 from "./resident_vs_non_resident.vue?vue&type=style&index=0&id=02542f7d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02542f7d",
  null
  
)

export default component.exports