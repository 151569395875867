<template>
    <div class="landing-wrapper">
        <t-header />
        <div class="container blog_text blog-post-wrapper">
            <div class="col-md-10 col-lg-8 mx-auto">
                <div class="mb-4">
                    <h1 class="mb-4">
                        Resident Alien vs. Non-resident Alien – A Complete Guide
                        Including Status Determination and U.S. Tax Filing
                        Requirements
                    </h1>
                    <p class="blog_author_text">
                        The Thriftax Team
                        <br />
                        Mar 13 2021 · 11 min read
                    </p>
                </div>
                <div class="position-relative mb-5">
                    <img
                        class="card-img"
                        :src="getImgUrl('students')"
                        alt="Image Description"
                    />
                </div>
                <p>
                    As a foreigner present in the US, you may fall under one of
                    the two following categories – resident alien or
                    non-resident alien. These residency categories do not imply
                    anything about your citizenship or lawful residency (also
                    known as green card) status. Rather, they are simply your
                    residency status for tax purposes determined by the IRS. You
                    may be present in the U.S. on a certain visa and still be
                    considered a resident alien.
                    <br />
                    <br />
                    What’s the difference between a resident and a non-resident
                    alien? There are differences in the income types and sources
                    that you are required to report to IRS, expenses and
                    deductions rules for tax purposes, and more. For example, as
                    a resident alien you are being taxed the same way as a U.S.
                    citizen, meaning the IRS will tax you on almost all income
                    types from every country. As a non-resident alien on the
                    other hand, you are not required to report non-US-sourced
                    income and will not get taxed on it.
                </p>
                <h2 class="mt-6 mb-3">Who Is a Non-resident Alien?</h2>
                <p>
                    The IRS defines 2 tests to determine residency status of
                    foreigners (Non-U.S. Citizens)
                </p>
                <ol class="pl-4">
                    <li class="h3">Green Card Test</li>
                    <div class="mt-2 mb-4">
                        Simply put, if you had a green card during the tax year
                        you are deemed a resident for the entire year. If you
                        don’t have a Green Card, or haven't had Green Card
                        application approved, this test is irrelevant to you.
                    </div>
                    <li class="h3">The Substantial Presence Test</li>
                    <div class="mt-2 mb-4">
                        If you are NOT on an F, J, M or Q visa (these visas
                        exempt you from this test for several years – see
                        below), then your residency status is determined based
                        on the number of days you were present in the U.S.
                        during the tax year and the two prior years. Let’s
                        determine your residency status:
                        <ol type="a" class="pl-4 mt-2">
                            <li class="mb-2">
                                Were you physically present in the U.S. for at
                                least 31 days during the tax year?
                                <br />
                                No – You are a non-resident alien
                                <br />
                                Yes – Continue to (b)
                            </li>
                            <li>
                                Count the number of days you were in the U.S. as
                                follows:
                                <ul class="pl-4">
                                    <li>
                                        <strong>
                                            All the days in the current tax
                                            year.</strong
                                        >
                                        Count all the days you were present in
                                        the U.S. during the tax year. For
                                        instance, if you are filing a
                                        {{ new Date().getFullYear() - 1 }} tax
                                        return, count all the days you were
                                        present in the U.S. in
                                        {{ new Date().getFullYear() - 1 }}.
                                    </li>
                                    <li>
                                        <strong
                                            >1/3 of the days 1 year prior the
                                            tax year.</strong
                                        >
                                        Count the days you were present in the
                                        U.S. in the year prior to the tax year
                                        and divide the result by 3. If you are
                                        filing a
                                        {{ new Date().getFullYear() - 1 }} tax
                                        return and were present in the U.S. for
                                        120 days in 2019 you should count
                                        120/3=40 days.
                                    </li>
                                    <li>
                                        <strong>
                                            1/6 of the days 2 years prior the
                                            tax year.
                                        </strong>
                                        Count the days you were present in the
                                        U.S. in the year prior to the tax year
                                        and divide the result by 6. If you are
                                        filing a
                                        {{ new Date().getFullYear() - 1 }} tax
                                        return and were present in the U.S. for
                                        180 days in 2018 you should count
                                        180/6=20 days.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </ol>
                <p>
                    Now sum the results from each year based on the calculation
                    above. Did you get a number higher than 183 days? You are a
                    resident alien (unless you are on an F, J, Q or M visa). Got
                    a number lower than 183 days? You are a nonresident alien.
                </p>
                <h2 class="mt-6 mb-3">
                    Exempt Individuals – F, J, M or Q Visa Holders
                </h2>
                <p>
                    If you are on an F, J, M or Q visa, you are considered an
                    “Exempt Individual”. These visa holders are NOT exempt from
                    filing taxes – rather they are exempt from counting days for
                    the substantial presence test for several years, depending
                    on their visa and status – see more below. Please note –
                    this exemption is not optional, but mandatory. The taxpayer
                    can’t select the status that suits their case just because
                    they want to. The IRS decided this for you and expects you
                    to file according to the law.
                </p>
                <h3 class="mt-3 mb-1">
                    Students on F-1, J-1 or M-1 visas (and their dependents)
                </h3>
                <p>
                    You are exempt from the substantial presence test for 5
                    years as an international student or as a dependent of one.
                    Your exemption will end on your 6th year on your student
                    visa, so you can start counting days for the substantial
                    presence test only on your 6th year. Note: you still won’t
                    be able to include days from previous years for the
                    substantial presence test. In other words, you will have to
                    be present in the U.S. for at least 183 days during your 6th
                    year to be considered a resident alien. If you switch to a
                    non-exempt visa such as H-1B you may start counting days for
                    the substantial presence test after the switch.
                </p>
                <h3 class="mt-3 mb-1">
                    Professors, scholars, researchers, trainees and other
                    exchange visa visitors on J-1, Q-1 or Q-2 visas (and their
                    dependents)
                </h3>
                <p>
                    You are exempt from the substantial presence test for 2
                    years. Your exemption will end on your 3rd year in the U.S.
                    on this visa, so you can start counting days for the
                    substantial presence test only on your 3rd year. Note: you
                    still will not be able to include days from previous years
                    for the substantial presence test. If you switch to a
                    non-exempt visa such as H-1B you may start counting days for
                    the substantial presence test after the test.

                    <br /><br />
                    In conclusion, if you are in the U.S. on an F, J, M or Q
                    visa, you are most likely a non-resident alien for 2 or 5
                    years from the year of your first arrival. For more
                    information about exempt individuals,
                    <a
                        href="https://www.irs.gov/individuals/international-taxpayers/substantial-presence-test"
                        >click here</a
                    >.
                </p>

                <h2 class="mt-6 mb-3">
                    Nonresident Alien vs. Resident Alien – Tax Pros and Cons
                </h2>
                <p>
                    Although you cannot select your residency status, you might
                    be wondering about the pros and cons of each status, so
                    let’s quickly review them:
                </p>
                <h3 class="font-weight-bold">Non-resident Alien Pros:</h3>
                <ul class="pl-4">
                    <li>Exempt from paying taxes on non-U.S. income</li>
                    <li>
                        Can utilize tax treaties to reduce their taxable income.
                        Tax treaties are available for employment income,
                        fellowship income and even capital gains. Your country
                        of residence will determine the specific benefits.
                    </li>
                    <li>
                        May be exempt from paying FICA taxes (social security
                        tax and Medicare tax). This is relevant only for F-1,
                        J-1, M-1, Q-1 and Q-2 visa holders, so long they are
                        non-resident aliens. This benefit alone sums up to 7.65%
                        of income tax saved! Read more about this exemption
                        <a
                            href="https://www.irs.gov/individuals/international-taxpayers/foreign-student-liability-for-social-security-and-medicare-taxes"
                            >HERE</a
                        >.
                    </li>
                </ul>
                <h3 class="font-weight-bold">Non-resident Alien Cons:</h3>
                <ul class="pl-4">
                    <li>
                        Cannot file joint tax returns – this is relevant for
                        married non-residents.
                    </li>
                    <li>
                        Cannot take the standard deduction. Note: Indian
                        residents can take the standard deduction under a tax
                        treaty.
                    </li>
                    <li>
                        Cannot utilize child and other dependent tax credits.
                        Note: residents of Canada, Mexico, India and South Korea
                        may be able take these credits under a tax treaty,
                        depending on their children’s residency and other
                        factors.
                    </li>
                </ul>
                <h3 class="font-weight-bold">Resident Alien Pros:</h3>
                <ul class="pl-4">
                    <li>Can take the standard deduction</li>
                    <li>
                        Can file joint tax returns (relevant for married
                        couples)
                    </li>
                    <li>Can utilize child and other dependent tax credits</li>
                </ul>
                <h3 class="font-weight-bold">Resident Alien Cons:</h3>
                <ul class="pl-4">
                    <li>
                        MUST pay FICA taxes, even if they are on a visa that
                        would have provided them with this exemption as a
                        non-resident alien.
                    </li>
                    <li>
                        Must report and pay taxes on income from outside the
                        U.S.
                    </li>
                </ul>

                <h2 class="mt-6 mb-3">
                    Avoid Common Residency-Related Mistakes – Select the Right
                    Software
                </h2>
                <p>
                    <strong
                        >If your status is non-resident alien, you cannot file
                        with online tax filing solutions for residents such as
                        TurboTax or H&R Block </strong
                    >. These providers will file form 1040 for you rather than
                    the required 1040-NR. Moreover, if you are exempt from FICA
                    taxes under an F, J, M or Q visa and file your taxes as a
                    resident alien (1040) your mistake may be considered as a
                    tax fraud! Comply with your residency status and file
                    accordingly. It is important to note that neither TurboTax,
                    H&R Block nor any other resident tax filing solutions will
                    check for your residency as part of the filing process.
                    Thus, if you file with these providers you will be able to
                    complete filing, but obviously with a mistake. Thriftax will
                    always determine your residency status at the beginning of
                    the filing process. If you are deemed a resident alien, our
                    software will direct you to file elsewhere with a tax filing
                    solution for residents.
                </p>
                <h2 class="mt-6 mb-3">
                    Non-resident Alien U.S. Filing Requirements – What Exactly
                    You Should File
                </h2>
                <p>
                    If you are a non-resident alien on an F, J, M or Q visa
                    (including F1, F2, J1, J2, M1, M2, Q1, Q2 and Q3), you are
                    required to file form 8843, even if you didn't have U.S.
                    income. The form asks questions about your personal
                    information, visa types and visa status, visits to the US,
                    and more. You must file and mail your 8843 form to the IRS
                    by June 15th of the current year. If you have dependents
                    (family members on a dependent visa) they should file and
                    mail their own 8843 forms as well.
                    <br /><br />
                    Did you have U.S.-sourced income last year ({{
                        new Date().getFullYear() - 1
                    }})? You should file a full federal tax return using form
                    1040-NR. Based on the state you are living in and the state
                    your income was generated in, you may also be required to
                    file a state tax return.
                    <strong>
                        You should file and mail your federal (and state, if
                        applicable) tax return(s) by April 15th of the current
                        year.</strong
                    >
                    <br /><br />
                    Thriftax can help you determine whether you are required to
                    only file 8843 or a full federal tax return and
                    automatically file the forms for you by asking a few simple
                    questions. We will walk you through the entire process and
                    provide completed ready-to-be-mailed forms, along with user
                    friendly instructions on how, where, and when to mail them.
                    <br /><br />
                    Try Thriftax today, maximize your non-resident tax refund by
                    utilizing over 150 tax treaty articles, deductions and
                    credits, ensure 100% accuracy and comply with the tax laws
                    and your visa’s requirements to maintain a good standing.
                </p>

                <ReadyToFileBanner class="mt-8" />
                <blog-badges />
            </div>
        </div>
        <t-footer />
    </div>
</template>

<script>
import ReadyToFileBanner from '@/components/landings/ready-to-file-banner';
import BlogBadges from './blog-badges';

export default {
    components: {
        ReadyToFileBanner: ReadyToFileBanner,
        BlogBadges,
    },
    methods: {
        getImgUrl(image) {
            var images = require.context('@/assets/img/', false, /\.jpg$/);
            return images('./' + image + '.jpg');
        },
    },
};
</script>

<style scoped>
ul li {
    /* Bullet color */
    list-style-type: disc;
}
</style>
